@use 'src/styles/_variables';

$chat-support-z-index: 99999;

// Container holding the entire display overlay view.
.custom-flyout-container {
  width: 100%;
  height: 100%;
  z-index: $chat-support-z-index;
}

// Left side of the flyout container. Used mostly to detect clicks. This is implemented by moving the entire view off screen hence the `left: -500vw`.
.custom-flyout-container-left {
  width: calc(100vw - 600px);
  height: 100vh;
  left: -500vw;
  opacity: 100;
  position: fixed;
  z-index: $chat-support-z-index;
}

// If the left side should be visible, set the `left` proeprty to be in view.
.custom-flyout-container-left-visible {
  left: 0;
}

// Visible contents of the flyout. This is implemented by moving the entire view off screen hence the `left: -500vw`
.custom-flyout-contents {
  width: 600px;
  height: 100%;
  flex: auto;
  overflow: hidden;
  position: fixed;
  right: -500vw;
  z-index: $chat-support-z-index;
  background: white;
}

// When it should be visible, adjust the position ot be visible.
.custom-flyout-contents-visible {
  right: 0;
}

// Drop shadow to match the Stencil Flyout component.
.custom-flyout-contents-visible-drop-shadow {
  box-shadow: rgba(35, 47, 62, 0.2) 0 0 10px;
}

// Visible side container header
.custom-flyout-contents-header {
  height: 68px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: variables.$primary70
}

// Adjust positioning between elements within the header.
.custom-flyout-contents-header-contents{
  &:first-child {
    padding-left: 25px;
    color: white;

  }
  &:last-child {
    right: 25px;
  }

  button {
    &:last-child {
      right: 25px;
      background-color: black;
    }
  }
}

// Expand the contents of the chat widget container to the width of the Row containing the cont
.chat-widget-div {
  width: 100%;
  height: calc(100vh - 60px);
}

.start-chat-button {
  background: white;
  color: variables.$primary70
}
