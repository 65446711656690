@use 'variables';

#app {
  #navbar {
    display: flex;
    flex: 1;
  }

  #languageSelector-toggle-button {
    border-radius: 5px;
  }
}
